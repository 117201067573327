@import 'src/styles/abstracts/variables';

.modal,
.confirm-modal,
.custom-modal {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.8rem;
  }

  &--error {
    color: $colorDustRed500;
  }
}

.modal,
.confirm-modal {
  text-align: center;
}
