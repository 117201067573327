@font-face {
  font-family: 'Manrope-ExtraLight';
  src: url('~/src/assets/fonts/Manrope-ExtraLight.ttf');
  font-style: normal;
  font-weight: 200;
}
//
@font-face {
  font-family: 'Manrope-Light';
  src: url('~/src/assets/fonts/Manrope-Light.ttf');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Manrope-Regular';
  src: url('~/src/assets/fonts/Manrope-Regular.ttf');
  font-style: normal;
  font-weight: 400;
}

//@font-face {
//  font-family: 'Manrope-Medium';
//  src: url('~/src/assets/fonts/Manrope-Medium.ttf');
//  font-style: normal;
//  font-weight: 500;
//}

@font-face {
  font-family: 'Manrope-SemiBold';
  src: url('~/src/assets/fonts/Manrope-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Manrope-Bold';
  src: url('~/src/assets/fonts/Manrope-Bold.ttf');
  font-style: normal;
  font-weight: 700;
}

//@font-face {
//  font-family: 'Manrope-ExtraBold';
//  src: url('~/src/assets/fonts/Manrope-ExtraBold.ttf');
//  font-style: normal;
//  font-weight: 800;
//}

body {
  letter-spacing: -0.02rem;
}

.display {
  font-family: 'Manrope-Bold', sans-serif;

  &--lg {
    font-size: 9.6rem;
    line-height: 11.2rem;
  }

  &--md {
    font-size: 5.2rem;
    line-height: 6.4rem;
  }

  &--sm {
    font-size: 4.4rem;
    line-height: 5.2rem;
  }

  &--xs {
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
}

.heading {
  font-family: 'Manrope-Bold', sans-serif;

  &--xxl {
    font-size: 4rem;
    line-height: 5.4rem;
  }

  &--xl {
    font-size: 3.6rem;
    line-height: 4.4rem;
  }

  &--lg {
    font-size: 3.2rem;
    line-height: 4rem;
  }

  &--md {
    font-size: 2.8rem;
    line-height: 3.6rem;
  }

  &--sm {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  &--xs {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

.label {
  font-family: 'Manrope-SemiBold', sans-serif;

  &--lg {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  &--md {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  &--sm {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  &--xs {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.paragraph {
  font-family: 'Manrope-Regular', sans-serif;

  &--lg {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  &--md {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  &--sm {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  &--xs {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}

.description {
  font-family: 'Manrope-Light', sans-serif;
  &--lg {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  &--md {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  &--sm {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  &--xs {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}

$textGrayColors: (
  '100': $colorGray100,
  '200': $colorGray200,
  '300': $colorGray300,
  '400': $colorGray400,
  '500': $colorGray500,
  '600': $colorGray600,
  '700': $colorGray700,
  '800': $colorGray800,
  '900': $colorGray900,
);

@each $name, $value in $textGrayColors {
  .text-gray-#{$name} {
    color: $value;
  }
}

$textColors: (
  'calendula-gold-600': $colorCalendulaGold600,
  'polar-green-600': $colorPolarGreen600,
  'primary-600': $colorPrimary600,
  'dust-red-600': $colorDustRed600,
);

@each $name, $value in $textColors {
  .text-#{$name} {
    color: $value;
  }
}
//Text Transform

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.text-center {
  text-align: center;
}

.text-weight-400 {
  font-family: 'Manrope-Regular', sans-serif;
}
