@import 'src/styles/abstracts/variables';

.sider {
  background-color: $colorGray100 !important;

  &__logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem;
    background-color: $colorGray100;
    color: $colorGray100;
  }
}
