@import 'src/styles/abstracts/variables';

.input {
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-content: stretch;

  &__addon {
    margin-top: 0.4rem;
  }
}

.textarea {
  &__label {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }

  &__icon {
    cursor: pointer;
  }
}
