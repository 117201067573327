@import 'src/styles/abstracts/variables';

.upload-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  height: 100%;

  .upload {
    position: relative;
    background-color: $colorGray300;
    border: 1px dashed $colorGray500;
    border-radius: 0.8rem;
    height: 40%;

    &-text {
      position: absolute;
      text-align: center;
      display: inline-block;
      background-color: transparent;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);

      &__label {
        color: $colorGray900;
      }

      &__description {
        color: $colorGray700;
      }
    }
  }
}
