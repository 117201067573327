@import 'src/styles/abstracts/variables';

.ant-divider {
  background-color: $colorGray400;
  margin: 0;
  padding: 0;
}

.ant-anchor-wrapper {
  .ant-anchor {
    background-color: $colorGray100;
    border-radius: 0.8rem;
    padding-inline-start: 0;
    margin-top: 2.4rem;

    .ant-anchor-link {
      padding: 1.8rem 2.4rem;
    }
  }

  &:not(.ant-anchor-wrapper-horizontal) {
    .ant-anchor {
      &::before {
        border-inline-start: 0;
      }

      .ant-anchor-link-active {
        border-right: 2px solid $colorPrimary500;
      }
    }

    .ant-anchor-ink.ant-anchor-ink-visible {
      display: none !important;
    }
  }
}

.ant-picker-panel {
  .ant-picker-time-panel {
    width: 28.5rem;

    .ant-picker-content {
      .ant-picker-time-panel-cell-selected {
        margin-inline: 0;

        .ant-picker-time-panel-cell-inner {
          width: 100%;
          border-radius: 0;
        }
      }
    }
  }
}

.ant-segmented {
  padding: 0;
  width: max-content;
  height: max-content;

  .ant-segmented-group {
    .ant-segmented-item {
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.modal,
.confirm-modal,
.custom-modal {
  .ant-modal-content {
    padding: 0;

    .ant-modal-header {
      padding: 1.6rem 2.4rem;
      margin-bottom: 0;
    }

    .ant-modal-body {
      padding: 2.4rem;
      border-top: 1px solid $colorGray400;
    }

    .ant-modal-footer {
      padding: 1.2rem 2.4rem;
      margin-top: 0;
      border-top: 1px solid $colorGray400;
    }
  }
}

.ant-popover {
  .ant-popover-inner {
    padding: 0;
  }
}

.ant-upload-wrapper {
  .ant-upload-list {
    .ant-upload-list-item {
      text-align: left;
    }
  }

  &.none {
    .ant-upload-drag {
      display: none;
    }
  }
}

.ant-table {
  .ant-table-thead {
    // Divider in select column
    .ant-table-selection-column::before {
      position: absolute;
      top: 50%;
      content: '';
      width: 1px;
      height: 1.6em;
      inset-inline-end: 0;
      background-color: $colorGray400;
      transform: translateY(-50%);
      transition: background-color 0.2s;
    }
  }
}

.ant-tag {
  margin-inline-end: 0;
}

.ant-tabs {
  .ant-tabs-nav {
    flex-wrap: wrap;
  }
}

.ant-tooltip {
  max-width: 26.3rem;
  background-color: $colorGray900;
  color: $colorGray100;
  border-radius: 8px;

  .ant-tooltip-inner {
    padding: 1.2rem 0.8rem 0.6rem;
  }
}

.ant-table-content {
  overscroll-behavior-y: none;
}

.ant-menu-item {
  padding-left: 1.6rem !important;
}

.ant-menu-item-selected {
  svg {
    fill: $colorPrimary600;
  }
}

.ant-layout-sider-children {
  border-right: 1px solid $colorGray400;
}

.ant-layout-header {
  border-bottom: 1px solid $colorGray400;
}

.ant-card {
  color: $colorGray900;
  .ant-card-head {
    color: $colorGray900;
  }
}

.tabs-full-width {
  .ant-tabs-nav {
    width: 100% !important;
  }

  .ant-tabs-tab {
    display: block; /* centers text inside tabs */
    flex: 1;
    text-align: center;
    justify-content: center;
  }

  .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
  }

  .ant-tabs-tab-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    & > span {
      display: flex;
    }
  }
}

.header-tabs-color {
  .ant-tabs-nav {
    background-color: $colorGray100;
    border-top: 0.1rem solid $colorGray300;
    padding: 0.6rem 2.4rem 0;
  }
  .ant-tabs-tab-btn {
    font-size: 1.6rem;
  }
  .ant-tabs-tabpane {
    padding: 1.2rem 2.4rem;
  }
  .ant-tabs-content-holder {
    height: calc(100vh - 22rem);
    overflow-y: auto;
  }
}

.hidden-upload {
  &.ant-upload-wrapper {
    .ant-upload-drag {
      display: none;
    }
  }
}
