@import 'src/styles/abstracts/variables';

.body {
  height: 100%;
  width: 100%;
  color: $colorGray900;
  display: flex;
  flex-direction: column;

  overflow: auto;
  overscroll-behavior-y: none;

  &-header {
    background-color: $colorGray100;
    padding: 1.2rem 2.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 5.6rem;

    &__buttons {
      display: flex;
      gap: 0.8rem;
    }
  }

  &-header-two {
    background-color: $colorGray100;
    padding: 1.2rem 2.4rem;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    align-items: flex-start;
  }

  &__content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2.4rem;

    overflow-x: hidden;
    overflow-y: auto;
  }
}
