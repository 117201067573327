@import 'src/styles/abstracts/variables';

.table-wrap {
  overflow: hidden;
  width: 100%;
}

.table-header {
  overflow: hidden;
  table {
    table-layout: fixed;
    width: 100%;
    text-align: start;
    border-collapse: separate;
    border-spacing: 0;
    & .table-header-cell {
      background-color: $colorPrimary200;
      padding: 1.8rem 1.6rem;
      font-family: 'Manrope-SemiBold', sans-serif;
      &.text-right {
        text-align: right;
      }
      &.text-left {
        text-align: left;
      }
    }
  }
}

.table-body {
  overflow: auto;
  max-height: 300px;
  table {
    min-width: 100%;
    table-layout: fixed;
    text-align: start;
    border-collapse: separate;
    border-spacing: 0;

    td {
      padding: 1.8rem 1.6rem;
      overflow-wrap: break-word;
      position: relative;
      border-top: 0.1rem;
      border-left: 0;
      border-right: 0;
      border-style: solid;
      border-color: $colorGray400;
      border-bottom: 0rem;
      word-break: break-all;
      &.text-right {
        text-align: right;
      }
      &.text-left {
        text-align: left;
      }
      &.text-center {
        text-align: center;
      }
    }
  }
}
.table-header,
.table-body {
  & .col-name {
    width: 12rem;
    @include for_size(desktopHD) {
      width: 20rem;
    }
    @include for_size(desktop) {
      width: 12rem;
    }
    @include for_size(desktopMedium) {
      width: 16rem;
    }
  }
  & .col-price {
    width: 12rem;
    @include for_size(desktopHD) {
      width: 20rem;
    }
    @include for_size(desktop) {
      width: 12rem;
    }
    @include for_size(desktopMedium) {
      width: 16rem;
    }
  }
  & .col-quantity {
    width: 16rem;
    @include for_size(desktopHD) {
      width: 20rem;
    }
    @include for_size(desktopMedium) {
      width: 16rem;
    }
  }
  & .col-amount {
    width: 14rem;
    @include for_size(desktopHD) {
      width: 20rem;
    }
    @include for_size(desktop) {
      width: 14rem;
    }
    @include for_size(desktopMedium) {
      width: 16rem;
    }
  }
  & .col-action {
    width: 10rem;
    padding: 1.8rem 0rem;
  }
}

.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__search {
    display: flex;
    align-items: center;
    justify-items: flex-start;
    gap: 1.2rem;
    width: 80rem;

    &-input {
      width: 100%;
    }
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
}
