@import 'src/styles/abstracts/variables';

.signin {
  &__background {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8rem 0;
  }

  &__background-image {
    background: center no-repeat
      url('../../assets/images/regular/background-signin.png');
  }

  &__container {
    background-color: $colorGray100;
    border-radius: 1.2rem;
    width: 52rem;
    padding: 6.4rem 4rem;

    @media (max-width: 400px) {
      width: 90vw;
    }
  }
}
