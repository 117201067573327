@import 'src/styles/abstracts/variables';

.btn {
  padding: 0.7rem 1.2rem;
  display: flex;
  align-items: center;

  &-icon {
    padding: 0.8rem;
    width: 4rem !important;
  }

  svg {
    transition: all linear 0.25s;
  }

  &-gray:hover {
    svg {
      fill: $colorPrimary600;
    }
  }

  &-purple:hover {
    svg {
      fill: $colorPrimary500;
    }
  }

  &-red:hover {
    svg {
      fill: $colorDustRed400;
    }
  }

  &:disabled {
    svg {
      fill: $colorGray600;
    }
  }
}

.btn-icon {
  &.dirty {
    border-color: $colorPrimary600;

    svg {
      fill: $colorPrimary600;
    }
  }
}

.quantity-button {
  &.btn-icon {
    width: 3rem !important;
    padding: 0.4rem;
  }
}

.quantity-input {
  width: 5rem;
}
