@import 'src/styles/abstracts/variables';

.filter-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 36.8rem;
  height: 100vh;
  background-color: $colorGray100;
  z-index: 5;
  display: grid;
  grid-template-rows: auto 1fr auto;
  opacity: 0;
  transform: translateX(100%);
  transition: all ease-in-out 0.25s;

  &--active {
    opacity: 1;
    transform: translateX(0%);
  }

  &__header {
    padding: 1.2rem 2.4rem;
    border-bottom: 1px solid $colorGray400;
  }

  &__content {
    padding: 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &-footer {
    padding: 1.2rem 2.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $colorGray400;

    &__clear {
      color: $colorDustRed600;
      cursor: pointer;
    }
  }
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &--active {
    display: block;
  }
}
