@import 'src/styles/abstracts/variables';

.header {
  background-color: $colorGray100;
  height: 6.4rem;
  padding: 1.2rem 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $colorGray400;

  &__button {
    font-size: 1.8rem;
    transition: color 0.3s;
    cursor: pointer;

    &:hover {
      color: $colorPrimary500;
    }
  }
}

.line-height-none {
  line-height: 0;
}
