@import 'src/styles/abstracts/variables';

.color--primary {
  color: $colorPrimary500 !important;
}

// ERROR COLOR
.color--error {
  color: $colorDustRed500 !important;
}

.border--error {
  border: 1px solid $colorDustRed500 !important;
}

// VIRTUAL HEIGHT

.min-vh--fit {
  min-height: fit-content !important;
}

.vh--28 {
  height: 2.8rem !important;
}

.h-auto {
  height: auto !important;
}

.h-full {
  height: 100% !important;
}

.h-screen {
  height: 100vh;
}

// MARGIN SPACING
$remValues: (
  '0': 0,
  '1': 0.1rem,
  '2': 0.2rem,
  '4': 0.4rem,
  '6': 0.6rem,
  '8': 0.8rem,
  '12': 1.2rem,
  '16': 1.6rem,
  '20': 2rem,
  '24': 2.4rem,
  '28': 2.8rem,
  '32': 3.2rem,
  '36': 3.6rem,
  '40': 4rem,
  '48': 4.8rem,
  '60': 6rem,
  '72': 7.2rem,
  '84': 8.4rem,
  '128': 12.8rem,
);

@each $name, $value in $remValues {
  .-mt--#{$name} {
    margin-top: -$value;
  }
  .-mb--#{$name} {
    margin-bottom: -$value;
  }
  .-ml--#{$name} {
    margin-left: -$value;
  }
  .-mr--#{$name} {
    margin-right: -$value;
  }
  .-mx--#{$name} {
    margin-right: -$value;
    margin-left: -$value;
  }

  .m--#{$name} {
    margin: $value;
  }
  .mt--#{$name} {
    margin-top: $value;
  }
  .mr--#{$name} {
    margin-right: $value;
  }
  .mb--#{$name} {
    margin-bottom: $value;
  }
  .ml--#{$name} {
    margin-left: $value;
  }
  .mx--#{$name} {
    margin-left: $value;
    margin-right: $value;
  }
  .my--#{$name} {
    margin-top: $value;
    margin-bottom: $value;
  }

  .p--#{$name} {
    padding: $value !important;
  }
  .pt--#{$name} {
    padding-top: $value;
  }
  .pr--#{$name} {
    padding-right: $value;
  }
  .pb--#{$name} {
    padding-bottom: $value;
  }
  .pl--#{$name} {
    padding-left: $value;
  }
  .px--#{$name} {
    padding-left: $value;
    padding-right: $value;
  }
  .py--#{$name} {
    padding-top: $value;
    padding-bottom: $value;
  }
}

// GAP
.gap--4 {
  gap: 0.4rem !important;
}
.gap--8 {
  gap: 0.8rem !important;
}

.gap--12 {
  gap: 1.2rem !important;
}

.gap--16 {
  gap: 1.6rem !important;
}

.gap--24 {
  gap: 2.4rem !important;
}

.gap--28 {
  gap: 2.8rem !important;
}

.gap-x--12 {
  column-gap: 1.2rem !important;
}

.gap-x--24 {
  column-gap: 2.4rem !important;
}

.gap-y--12 {
  row-gap: 1.2rem !important;
}

.gap-y--24 {
  row-gap: 2.4rem !important;
}

.gap-y--28 {
  row-gap: 2.8rem !important;
}

// WIDTH
.w--50 {
  width: 50% !important;
}

.w--60 {
  width: 60% !important;
}

.w--100 {
  width: 100% !important;
}

.w--fit {
  width: fit-content !important;
}

$widthValues: (
  '0': 0rem,
  '150': 15rem,
  '200': 20rem,
  '300': 30rem,
  '400': 40rem,
);

@each $name, $value in $widthValues {
  .w-#{$name} {
    width: $value;
  }
}

.w--175 {
  width: 17.5rem;
}

.w--500 {
  width: 50rem;
}

.container {
  @media (min-width: 768px) {
    max-width: 76.8rem;
  }
  @media (min-width: 1024px) {
    min-width: 102.4rem;
  }
}

// DISPLAY FLEX
.d-flex {
  display: flex !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-none {
  flex: none;
}

.justify-center {
  justify-content: center !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-items-center {
  justify-items: center !important;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

// GRID

.grid {
  display: grid !important;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.border {
  border-width: 1px;
  border-style: solid;
}

.border-gray-400 {
  border-color: $colorGray400;
}

// BORDER RADIUS
.br--6 {
  border-radius: 0.6rem !important;
}

.br--8 {
  border-radius: 0.8rem !important;
}

.rounded-r-none {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.rounded-l-none {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.rounded-br-8 {
  border-bottom-right-radius: 0.8rem;
}
.rounded-bl-8 {
  border-bottom-left-radius: 0.8rem;
}

// CURSOR POINTER
.cursor--pointer {
  cursor: pointer !important;
}

.cursor--not-allowed {
  cursor: not-allowed !important;
}

// FLOAT
.fl {
  float: left !important;
}

.fr {
  float: right !important;
}

// GRAY BACKGROUND
.space--gray {
  padding: 2.4rem;
  border-radius: 0.8rem;
  display: flex;
  background-color: $colorGray300;
}

// DELETE ICON AND TEXT
.delete {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: $colorDustRed500;
  cursor: pointer;
}

// Background color

$backgroundColorValues: (
  'gray-100': $colorGray100,
  'gray-200': $colorGray200,
  'primary-200': $colorPrimary200,
  'primary-300': $colorPrimary300,
  'primary-600': $colorPrimary600,
  'dust-red-100': $colorDustRed100,
  'calendula-gold-200': $colorCalendulaGold200,
  'calendula-gold-100': $colorCalendulaGold100,
);

@each $name, $value in $backgroundColorValues {
  .background-#{$name} {
    background-color: $value !important;
  }

  .border-color-#{$name} {
    border-color: $value !important;
  }
}

hr {
  margin: 0;
  padding: 0;
  display: block;
  border: 0;
  border-top: 1px solid $colorGray400;
  padding: 0;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

// cursor
.cursor-pointer {
  cursor: pointer;
}
.cursor-zoom-in {
  cursor: zoom-in;
}

//whitespace
.whitespace-pre-line {
  white-space: pre-line;
}

//Position
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}

//Top / Right / Bottom / Left
.inset-0 {
  inset: 0px;
}

//Max width
$maxWidthValues: (
  '200': 20rem,
  '320': 32rem,
  '600': 60rem,
  '768': 76.8rem,
  '1024': 102.4rem,
);

@each $name, $value in $maxWidthValues {
  .max-width-#{$name} {
    max-width: $value;
  }
  .min-width-#{$name} {
    min-width: $value;
  }
}

.bg-image {
  background-image: url('../../assets/images/regular/bg-payment.png');
  background-position: center;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.font-weight-600 {
  font-weight: 600;
}
