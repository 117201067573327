@import 'src/styles/abstracts/variables';

.col-product {
  @include for_size(desktopHD) {
    width: calc(100% - 37.8rem - 2.4rem);
  }
}
.col-info {
  @include for_size(desktopHD) {
    width: 37.8rem;
  }
}

.status {
  &-list {
    &-item {
      &--success {
        background-color: $colorPolarGreen100;
        border: 1px solid $colorPolarGreen300;
        color: $colorPolarGreen600;
      }

      &--pending {
        background-color: $colorPrimary300;
        border: 1px solid $colorPrimary400;
        color: $colorPrimary500;
      }

      &--expired {
        background-color: $colorOrange100;
        border: 1px solid $colorOrange400;
        color: $colorOrange600;
      }

      &--cancelled {
        background-color: $colorPrimary200;
        border: 1px solid $colorPrimary200;
        color: $colorGray700;
      }

      &--waiting {
        background-color: $colorCalendulaGold100;
        border: 1px solid $colorCalendulaGold200;
        color: $colorCalendulaGold600;
      }

      &--rejected {
        background-color: $colorDustRed100;
        border: 1px solid $colorDustRed300;
        color: $colorDustRed600;
      }
    }
  }
}

.order {
  &-details {
    &__countdown {
      padding: 0.4rem 0.3rem;
      border-radius: 4px;
      background-color: $colorGray100;
      color: $colorDanger600;
    }
  }
}
