@import 'src/styles/abstracts/variables';

.overflow-auto {
  overflow: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overscroll-auto {
  overscroll-behavior: auto;
}

.overscroll-x-none {
  overscroll-behavior-x: none;
}

.overscroll-y-none {
  overscroll-behavior-y: none;
}
