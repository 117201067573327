@import 'src/styles/abstracts/variables';

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #f6ffed;
  border: 1px solid $colorPolarGreen300;
  color: $colorGray900;

  .Toastify__toast-body svg {
    fill: $colorPolarGreen600;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: #fff2f0;
  border: 1px solid $colorDustRed200;
  color: $colorGray900;

  .Toastify__toast-body svg {
    fill: $colorDustRed500;
  }
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
  background-color: $colorPolarGreen300;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background-color: $colorDustRed200;
}

.Toastify__close-button > svg {
  fill: rgba(0, 0, 0, 0.45);
}
