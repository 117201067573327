// Colors
$colorPrimary100: #edebfd;
$colorPrimary200: #f7f9fa;
$colorPrimary300: #e6f7ff;
$colorPrimary400: #91d5ff;
$colorPrimary500: #188fff;
$colorPrimary600: #208fff;

$colorDustRed100: #fff1f0;
$colorDustRed200: #ffccc7;
$colorDustRed300: #ffa39e;
$colorDustRed400: #ff7875;
$colorDustRed500: #ff4d4f;
$colorDustRed600: #f5222d;
$colorDustRed700: #cf1322;
$colorDustRed800: #a8071a;
$colorDustRed900: #820014;
$colorDustRed1000: #5c0011;

$colorGray100: #fff;
$colorGray200: #f8f7fa;
$colorGray300: #f3f4f5;
$colorGray400: #ebeef0;
$colorGray500: #d5d7d9;
$colorGray600: #bbbdbf;
$colorGray700: #898b8c;
$colorGray800: #575859;
$colorGray900: #252526;

$colorPolarGreen100: #f6ffed;
$colorPolarGreen300: #b7eb8f;
$colorPolarGreen600: #52c41a;

$colorCalendulaGold100: #fffbe6;
$colorCalendulaGold200: #fff1b8;
$colorCalendulaGold600: #faad14;

$colorDanger600: #d44333;

$colorOrange100: #fff7e6;
$colorOrange400: #ffd591;
$colorOrange600: #fa8c16;
// Font-size
$defaultFontSize: 1.4rem;
